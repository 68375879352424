import {
  AirlineStops,
  ArrowBack,
  ArrowForward,
  ArrowOutward,
  CurrencyPound,
  DateRange,
  Email,
  HighlightOff,
  House,
  Pause,
  Start,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getTenancyDetails,
  sendAgreementFileToLandLord,
} from "../../apis/property/tenancy";
import { SelectProperty } from "../../components/property/selectProperty";
import { CustomListItem } from "../../components/shared/customListItem";
import { convertDate } from "../../functions/modifyText";
import PropertyModel from "../../models/property/property";
import { TenancyDetails, Tenant } from "../../models/property/tenancy";
import { AppCtx } from "../../store/store";
import CustomListItemModel from "../../models/shared/customListItem";
import ContactDetails from "../../components/property/contactDetails";

export const Tenancy = () => {
  const navigate = useNavigate();
  const { setIsLoading, propertiesList, getLoggedUser } = useContext(AppCtx);
  const loggedUser = getLoggedUser();
  const { propertyId } = useParams();
  const [tenancyDetails, setTenancyDetails] = useState<TenancyDetails>();
  const [currentTenant, setCurrentTenant] = useState<Tenant>();
  const [selectedProperty, setSelectedProperty] = useState<PropertyModel>();
  useEffect(() => {
    if (propertyId && loggedUser) {
      fetchTenancyDetails();
    }
  }, [propertyId]);
  useEffect(() => {
    selectedProperty && navigate(`/properties/tenancy/${selectedProperty.id}`);
  }, [selectedProperty]);

  const fetchTenancyDetails = async () => {
    setIsLoading(true);
    let tempTenancyDetails = (await getTenancyDetails(
      propertyId!,
      loggedUser?.token!
    )) as unknown as TenancyDetails;
    if (tempTenancyDetails) {
      setTenancyDetails(tempTenancyDetails);
      if (tempTenancyDetails.tenants.length > 0) {
        setCurrentTenant(tempTenancyDetails.tenants[0]);
      } else {
        setCurrentTenant(undefined);
      }
      var tempSelectedProperty = propertiesList.find(
        (x) => x.id.toString() === propertyId
      )!;
      if (tempSelectedProperty) {
        setSelectedProperty(tempSelectedProperty);
      }
    }
    setIsLoading(false);
  };

  const tenancyContractListItems: CustomListItemModel[] = [
    {
      title: "Move in date",
      value: convertDate(tenancyDetails?.tenancyContract.moveInDate!),
      icon: <AirlineStops />,
    },
    {
      title: "Current tenancy start date",
      value: convertDate(tenancyDetails?.tenancyContract.tenancyStartDate!),
      icon: <Start />,
    },
    {
      title: "Fixed term ending",
      value: convertDate(tenancyDetails?.tenancyContract.fixedTermEnding!),
      icon: <Pause />,
    },
    {
      title: "Rent agreed",
      value: AppendFrequencyWithValue(),
      icon: <CurrencyPound />,
    },
    {
      title: "Reviewed rent",
      value: AppendFrequencyWithValue(false),
      icon: <CurrencyPound />,
    },
    {
      title: "Next rent review date",
      value: convertDate(tenancyDetails?.tenancyContract.nextRentReviewDate!),
      icon: <DateRange />,
    },
    {
      title: "Move out date",
      value: convertDate(tenancyDetails?.tenancyContract.moveOutDate!),
      icon: <ArrowOutward />,
    },
    {
      title: "Property Address",
      value: selectedProperty?.address,
      icon: <House />,
    },
  ];
  const handleChangeTenant = (goToPrevious?: boolean) => {
    if (tenancyDetails) {
      var tenants = tenancyDetails.tenants;
      if (tenants.length > 0) {
        let currentTenantIndex = tenants.indexOf(currentTenant!);
        var tenantToBeDisplayed = tenants[0];
        if (goToPrevious) {
          if (currentTenantIndex !== 0) {
            tenantToBeDisplayed = tenants[currentTenantIndex - 1];
          }
        } else if (currentTenantIndex < tenants.length) {
          tenantToBeDisplayed = tenants[currentTenantIndex + 1];
        }
        tenantToBeDisplayed && setCurrentTenant(tenantToBeDisplayed);
      }
    }
  };
  const handleChangeProperty = (property: PropertyModel) => {
    setSelectedProperty(property);
  };
  const sendAgreementFile = async () => {
    setIsLoading(true);
    await sendAgreementFileToLandLord(propertyId!, loggedUser?.token!);
    setIsLoading(false);
  };

  function AppendFrequencyWithValue(forRentAgreed = true) {
    let value = undefined;
    let frequencyValue = tenancyDetails?.tenancyContract.rentFrequency;
    if (forRentAgreed) {
      let rentAgreedValue = tenancyDetails?.tenancyContract.rentAgreed;
      if (rentAgreedValue) {
        if (frequencyValue) {
          value = `${rentAgreedValue} (${frequencyValue})`;
        } else {
          value = rentAgreedValue;
        }
      }
    } else {
      let reviewedRentValue =
        tenancyDetails?.tenancyContract.reviewedRentAmount;
      if (reviewedRentValue) {
        if (frequencyValue) {
          value = `${reviewedRentValue} (${frequencyValue})`;
        } else {
          value = reviewedRentValue;
        }
      }
    }
    return value;
  }

  return (
    <Container maxWidth="sm">
      <Card variant="outlined" sx={{ borderRadius: 3, mb: 3 }}>
        <CardContent sx={{ p: 3, alignItems: "center" }}>
          {loggedUser?.isLandLord && (
            <SelectProperty
              selectedPropertyId={propertyId!}
              getSelected={handleChangeProperty}
            />
          )}
          <Avatar
            src={
              currentTenant &&
              `data:image/jpeg;base64,${currentTenant?.photoUrl}`
            }
            sx={{
              width: "200px",
              height: "200px",
              margin: "auto",
              objectFit: "contain",
              mt: 1,
            }}
          />
          {tenancyDetails && (
            <Chip
              sx={{
                margin: "auto",
                display: "flex",
                width: "fit-content",
                mt: 1
              }}
              label={tenancyDetails.tenancyContract.contractStatus}
            />
          )}
          <IconButton
            sx={{
              marginLeft: "auto",
              display: "block",
              mt: -5,
              width: 45,
              height: 45,
            }}
            color="primary"
            onClick={sendAgreementFile}
            disabled={!tenancyDetails?.isAgreementAvailable}
          >
            <Tooltip title={"Send Agreement file."}>
              <Email fontSize={"inherit"} />
            </Tooltip>
          </IconButton>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <IconButton
              color="primary"
              size={"large"}
              onClick={() => handleChangeTenant(true)}
              disabled={
                tenancyDetails?.tenants.length === 0 ||
                tenancyDetails?.tenants.indexOf(currentTenant!) === 0
              }
            >
              <Tooltip title={"Previous Tenant"}>
                <ArrowBack fontSize="large" />
              </Tooltip>
            </IconButton>
            {currentTenant ? (
              <ContactDetails
                fullName={currentTenant.firstName + " " + currentTenant.surname}
                mainPhone={currentTenant.mainPhone}
                workPhone={currentTenant.workPhone}
                mobile={currentTenant.mobile}
                mail={currentTenant.email}
                isSuperAdmin={loggedUser?.isSuperAdmin}
              />
            ) : (
              "No tenants found!"
            )}

            <IconButton
              color="primary"
              size={"large"}
              onClick={() => handleChangeTenant()}
              disabled={
                tenancyDetails?.tenants.indexOf(currentTenant!) ===
                tenancyDetails?.tenants.length! - 1
              }
            >
              <Tooltip title={"Next Tenant"}>
                <ArrowForward fontSize="large" />
              </Tooltip>
            </IconButton>
          </Stack>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Grid container mt={2} columns={2}>
            {tenancyContractListItems.map((item, index) => (
              <CustomListItem index={index} item={item} key={index} />
            ))}
          </Grid>
          <Box display="flex" justifyContent="center">
            <Tooltip title={"Back to property page"}>
              <IconButton
                color="primary"
                onClick={() => navigate("/properties/" + propertyId!)}
              >
                <HighlightOff sx={{ fontSize: 60 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};
